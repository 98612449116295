<template>
  <div class="tabs is-boxed m-0">
    <ul>
      <div
        v-for="(option, idx) in options"
        :key="idx"
      >
        <li
          v-if="hasPermission(option.permission)"
          :class="{ 'is-active': option.isSelected }"
        >
          <a @click="toggle(option.action)">
            <DashboardSearchOption v-bind="{ option }" />
          </a>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DashboardSearchOptions',
  components: {
    DashboardSearchOption: () => import('./DashboardSearchOption')
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isCompanionUser', 'hasAutoCheck'])
  },
  methods: {
    toggle(action) {
      if (!this.isCompanionUser && action === 'companion') {
        this.$mxp.track('dashboard_upsell-companion')
        this.$modal.open('companion/UpsellCompanion')
      } else if (!this.hasAutoCheck && action === 'check') {
        this.$mxp.track('dashboard_upsell-autocheck')
        this.$modal.open('companion/UpsellAutocheck')
      } else {
        this.$mxp.track(`dashboard_toggle_${action}`)
        this.$emit('change', action)
      }
    },
    hasPermission(permission) {
      return permission === true || this[permission]
    }
  }
}
</script>
